@import url('https://fonts.cdnfonts.com/css/strasua');

@font-face {
  font-family: NTSomic;
  font-weight: normal;
  src: url(./fonts/NTSomic-Regular.woff2);
}

@font-face {
  font-family: NTSomic;
  font-weight: 600;
  src: url(./fonts/NTSomic-Medium.woff2);
}

@font-face {
  font-family: NTSomic;
  font-weight: 700;
  src: url(./fonts/NTSomic-Bold.woff2);
}

@font-face {
  font-family: NeueMachinaRegular;
  font-weight: normal;
  src: url(./fonts/Neue\ Machina\ Regular.woff);
}

@font-face {
  font-family: NeueMachinaBold;
  font-weight: bold;
  src: url(./fonts/Neue\ Machina\ Ultrabold.woff2);
}