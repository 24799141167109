html {
  font-size: 20px;
}
.app {
}
body {
  background-color: #FEFEFE;
  font-family: NTSomic, sans-serif;
  overflow-x: hidden;
}
h1, h2, h3 {
  font-family: 'NeueMachinaRegular', sans-serif;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.5vw;
}
h2 {
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 2rem;
}
h3 {
  font-size: 2vw;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 2rem;
}
h4 {
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 1.5;
}
button {
  background-color: #ffffff;
}
.helper-heading {
  font-weight: 500;
  color: #142828;
}
img {
  max-width: 100%;
}
a.email {
  color: aliceblue;;
}
.text-center {
  text-align: center;
}
.bg-rounded {
  display: inline-block;
  border-radius: 30px;
  padding: 0px 10px 2px;
}
.bg-pink {
  background-color: #F190F9;
  color: white;
}
.bg-green {
  background-color: #00ED85;
}
.bg-blue {
  background-color: #2791F7;
  color: white;
}
.bg-yellow {
  background-color: #FFD465;
}
.btn {
  display: inline-block;
  color: black;
  padding: 12px 30px;
  text-decoration: none;
  border: 3px solid #2791F7;
  border-radius: 100px;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: 'NeueMachinaBold', sans-serif;
  transition: 0.2s;
}
.btn:hover {
  cursor: pointer;
  background-color: #2791F7;
  color: white;
}
.btn.primary {
  background-color: #2791F7;
  color: white;
}
.btn.primary:hover {
  background-color: #F190F9;
  border: 3px solid #F190F9;
}
ul li {
  margin: 16px 0;
  list-style-position: inside;
}
.px-xl {
  padding-bottom: 60px;
}
.pt-xl {
  padding-top: 60px;
}
.align-items-end {
  align-items: flex-end;
}
.credit {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding-bottom: 3px;
  border-bottom: 1px solid rgb(68, 68, 68);
}
.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.container.wide {
  max-width: 100%;
}
.navbar .logo {
  max-width: 150px;
}
.header {
  /* height: 80vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  padding: 120px 20px 50px;
  overflow: hidden;
  flex-direction: column;

  background: url(./assets/img/logo_fill.svg) no-repeat;
  background-size: 600px auto;
  background-position: right top;
  /* position: fixed; */
}
.header .heading {
  padding-left: 200px;
}
.heading_text {
  font-size: 6.2vw;
  letter-spacing: -6px;
  line-height: 0.85;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'NeueMachinaBold', sans-serif;
  position: relative;
  /* pointer-events: none; */
  margin-bottom: 40px;
}
.heading_text::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -180px;
  width: 80px;
  height: 80px;
  background: url(./assets/img/asterisk.svg) no-repeat;
  background-size: contain;
  animation: rotate 20s linear infinite;
  transition: 0.5s;
}
.heading:hover .heading_text::before {
  width: 100px;
  height: 100px;
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.heading .purple {
  color: #F190F9;
}
.heading .blue {
  color: #2791F7;
}
/* .heading::after {
  content: '';
  width: 300px;
  height: 300px;
  position: absolute;
  left: 200px;
  top: 200px;
  margin: auto;
  background-image: url(./assets/img/corona.svg);
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: exclusion;
  animation: rotate 50s infinite linear;
} */
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header .bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  padding-left: 200px;
}
.header .bottom > div {
  max-width: 510px;
}
main {
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.08);
  background-color: white;
  /* border-radius: 10px; */
  overflow: hidden;
  transform-origin: top center;
  padding-top: 60px;
  padding-bottom: 40px;
}
section {
  padding-bottom: 50px;
}
section.tall {
  min-height: 80vh;
}
.navbar_wrapper {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  top: 0;
  width: 100%;
  z-index: 10;
  gap: 20px;
}
.navbar > div:first-child, .navbar > div:last-child{
  flex: 1;
}
.navbar nav {
  display: flex;
  gap: 10px;
  align-items: center;
}
.navbar nav a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  font-weight: 700;
  font-size: 19px;
  transition: 0.2s;
  color: #1A1A1A;
}
.navbar nav a:hover {
  font-style: italic;
}
.self-center {
  align-self: center;
}
.split-row {
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  gap: 50px;
}
.split-row-head {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(103, 103, 103, 0.245);
}
.split-row-head h2 {
  margin-bottom: 0;
}
.half-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-transform: uppercase;
  font-size: 0.85rem;
}
.split-row .split-content {
  width: 80%;
  min-width: 0;
  line-height: 1.5;
}
.split-content ul li {
  list-style: none;
}
.horizontal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.hover-block {
  height: 70%;
  margin-top: 20px;
  border: 4px dashed rgb(248, 248, 248);
  background: url(./assets/img/cursor-hover.svg) no-repeat;
  background-size: 10%;
  background-position: center;
}
.hover-block:hover {
  cursor: none;
}
.service-block:not(:last-child) {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(202, 202, 202);
}
.service-block img {
  position: fixed;
  width: 25vw;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  border-radius: 10px;
}
.companies {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
}
.companies img {
  justify-self: center;
  max-height: 150px;
}
a.email {
  font-size: 1.2rem;
  color: inherit;
  padding-bottom: 5px;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 15px;
  transition: 0.1s;
}
footer {
}
footer .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
}
footer .logo {
  max-width: 150px;
}
footer .credits {
  text-align: right;
}
.text-right {
  text-align: right;
}
video {
  max-width: 100%;
  border-radius: 10px;
}
.folder {
  background-color: #2791F7;
  width: 100%;
  border-radius: 20px;
  padding: 80px 30px 30px;
  background: url(./assets/img/folder-head.webp) no-repeat;
  background-size: 100% 100%;
}
.icon-corona {
  position: relative;
}
.icon-corona::before {
  content: '';
  width: 400px;
  height: 400px;
  left: -450px;
  top: -50px;
  position: absolute;
  background: url(./assets/img/corona.svg) no-repeat;
  background-size: contain;
  animation: rotate 40s linear infinite;
}
span.corona {
  position: relative;
  z-index: 10;
}
span.corona::after {
  content: '';
  width: 5rem;
  height: 5rem;
  left: 0;
  right: 0;
  top: 10px;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 9;
  background: url(./assets/img/corona.svg) no-repeat;
  background-size: contain;
}

/*infocards*/
.infocards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
.infocard-head {
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}
.infocard-title {
  font-weight: 700;
  padding: 20px 0;
}
.infocard-description {
  color: #696969;
}
.testimonal-author-block {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}
.testimonal-author-avatar {
  width: 80px;
}
.testimonal-author-avatar img {
  border-radius: 50px;
}
.testimonal-author-name {
  font-weight: 700;
}
.testimonal-author-position {
  color: rgb(140, 150, 150);
}

/*company-slider*/
.companies-slider {
  position: relative;
}
.companies-slider:before {
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #ffffff, #00000000);
  z-index: 1;
}
.companies-slider:after {
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(-90deg, #ffffff, #00000000);
  z-index: 1;
}
.companies-slider-item {
  padding: 0 20px;
}
.companies-slider-item img {
  max-height: 100px;
}
.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}
.testimonal-slider {
  margin-bottom: 40px;
}

/*team gallery*/
.team-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.team-gallery-item img {
  aspect-ratio: .81 / 1;
  object-fit: cover;
  border-radius: 10px;
}

/*work-gallery*/
.work-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.work-gallery-item {
  position: relative;
  /* border: 1px solid #ddd; */
}
.work-gallery-item:hover {
  cursor: pointer;
}
.work-gallery-item:hover .work-gallery-image {
  filter: blur(6px);
}
.work-gallery-item:hover .work-gallery-description {
  opacity: 1;
}
.work-gallery-image {
  padding: 3rem;
  background-color: rgb(249, 249, 249);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
}
.work-gallery-image.bg-hc-pink {
  background-image: url(./assets/img/logo_fill_pink.svg);
  background-size: 50%;
}
.work-gallery-image.bg-hc-blue {
  background-image: url(./assets/img/logo_fill_blue.svg);
  background-size: 50%;
}
.work-gallery-image.bg-asterisk-blue {
  background-image: url(./assets/img/asterisk.svg);
  background-size: 65%;
}
.work-gallery-image.bg-asterisk-pink {
  background-image: url(./assets/img/asterisk_pink.svg);
  background-size: 65%;
}
.work-gallery-image img {
  aspect-ratio: 1.6 / 1;
  object-fit: cover;
  border-radius: 10px;
}
.work-gallery-description {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.2s;
}

.transition-circle {
  position: fixed;
  width: 120vw;
  height: 120vw;
  text-align: center;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin: auto;
  z-index: 999;
  background-color: #ffffff;
  pointer-events: none;
  opacity: 0;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
    margin-top: 20px;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*contact*/
.contact-wrapper {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 0px;
}
.contact-block {
  width: 700px;
  max-width: 100%;
}
.contact-block form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

/*inputs, form*/
form > *::-moz-placeholder{
    color:#666;
    opacity:1
}
form > *:-ms-input-placeholder{
    color:#666;
}
form > *::-webkit-input-placeholder{
    color:#666;
}
input, select {
  font-family: inherit;
  font-size: 1rem;
  padding: 8px 0;
  padding-left: 6px;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.112);
  outline: none;
  width: 100%;
  background-color: transparent;
  color: #666;
}
select:invalid {
  color: #666;
}
select {
  background-color: #ffffff;
}
input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #666;
    margin-right: 0.5em;
  }
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

/*TRANSITIONS*/
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  transform-origin: bottom;
  z-index: 999;
}
.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  transform-origin: top;
  z-index: 999;
}

/*===================SELECT====================*/
.lang-select__menu {
  background-color: #393d3a;
  /* color: white; */
}
.lang-select__option--is-focused{
  color: white!important;
}

@media screen and (max-width: 1200px) {
  .heading_text {
    letter-spacing: -3px;
  }
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  a.email {
    font-size: 2rem;
  }
  .horizontal-content {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  .header .heading {
    padding-left: 130px;
  }
  .heading_text {
    letter-spacing: -3px;
  }
  .header .bottom {
    padding-left: 130px;
  }
  .heading_text::before {
    left: -115px;
  }
  .work-gallery-image {
    padding: 50px;
  }
  .px-xl {
    padding-bottom: 30px;
  }
  .pt-xl {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 16px;
  }
  main {
    padding-top: 30px;
  }
  h2 {
    font-size: 1.5rem;
    text-align: center;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.15rem;
  }
  p {
    text-align: justify;
  }
  a.email {
    font-size: 5vw;
  }
  .credit {
    font-size: 0.8rem;
  }
  .btn {
    padding: 10px 20px;
  }
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  section {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .bg-rounded {
    padding: 4px 10px 2px;
  }
  .half-row {
    display: none;
  }
  .header {
    background-size: 300px auto;
    background-position-y: 50px;
    padding: 100px 20px 40px;
  }
  .header .heading {
    padding-left: 0;
  }
  .header .bottom {
    padding-left: 0;
    margin-top: 20px;
  }
  .heading_text {
    font-size: 12vw;
    margin-bottom: 10px;
  }
  .split-row {
    grid-template-columns: auto;
    gap: 30px;
  }
  .px-xl {
    padding-bottom: 15px;
  }
  .split-row .split-content {
    width: 100%;
  }
  .split-row-head {
    padding-bottom: 20px;
  }
  .infocards {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    row-gap: 30px;
  }
  .infocard-head {
    padding-bottom: 14px;
  }
  .infocard-title {
    padding: 14px 0;
  }
  .team-gallery {
    gap: 10px;
  }
  .work-gallery {
    gap: 10px;
    grid-template-columns: auto
  }
  .work-gallery-image {
    padding: 0;
    background-image: none!important;
  }
  .work-gallery-description {
    opacity: 1;
  }
  .work-gallery-image {
    filter: blur(1px);
  }
  .service-block img {
    position: relative;
    opacity: 1;
    width: unset;
  }
  .hover-block {
    border: 0;
  }
  .border-top {
    border: 0;
  }
  .companies-slider-item {
    padding: 0 10px;
  }
  .companies-slider:before, .companies-slider:after {
    width: 20px;
  }
  footer .container {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 35px;
  }
  footer .credits {
    text-align: center;
  }
  footer .skip {
    display: none;
  }
}

@media screen and (max-width: 545px) {
  .header .heading {
    letter-spacing: -1px;
    padding: 0;
    font-size: 12vw;
    margin-right: 20px;
  }
  .navbar nav {
    display: none;
  }
}